<template>
  <div v-loading="loading" class="table-area wrapper-trading">
    <div class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Chamka Trades</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          
        </div>
      </el-col>
      <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
        <div class="btn-wrapper" v-if="getRoleSlug != 'superadmin'">
   
          <!-- <el-button type="primary" @click="handleSearch()">Search</el-button>
          <el-button type="primary" @click="handleReset()" >Reset</el-button> -->
        </div>
      </el-col>


        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="trading-client">
            <el-form
              :model="formData"
              ref="refForm"
              label-position="top"
              :status-icon="true"
            >
              <el-row :gutter="10">
                
                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.script"
                      label="Script"
                      prop="script"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.script" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.segment"
                      label="Segment"
                      prop="segment"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.segment" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.userId"
                      label="User Id"
                      prop="userId"
                      :label-width="formLabelWidth"
                    >
                      <el-input v-model="listQuery.userId" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
               

                <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.status"
                      label="Status"
                      prop="status"
                      :label-width="formLabelWidth"
                    >
                    <el-select v-model="listQuery.status" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="Actve" value="active" ></el-option>
                        <el-option label="Close" value="close" ></el-option>
                    </el-select>
                    </el-form-item>
                  </div>
                </el-col>

                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.close_time"
                    label="Trade time"
                    prop="close_time"
                    :label-width="formLabelWidth"
                  >
                     <el-select v-model="listQuery.close_time" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="2 min" value="2" ></el-option>
                        <el-option label="5 min" value="5" ></el-option>
                        <el-option label="10 min" value="10" ></el-option>
                      </el-select>
                  </el-form-item>
                </div>
              </el-col>


                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                  <div class="search-wrapper">
                  <el-form-item label="" style="line-height: 40px;">
                    &nbsp;
                    </el-form-item>
                    <div class="broker-search-wrapper">
                    <el-button type="primary" @click="handleSearch()"
                      >Search</el-button
                    >
                    <el-button type="primary" @click="handleReset()"
                      >Reset</el-button
                    >
                    </div>
                  
                </div>
                  
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>

    </el-row>
    </div>
    <Table
      tableKey="trades"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableActionViewVisibility="false"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      :tablePagination="tablePagination"
      :showSummary="false"
       @edit="handleEdit($event)"
       @delete="handleDelete($event)"
       @view="handleViewDetails($event)"
      @pagination="handlePagination()"
    />
   
  </div>
</template>


<script>
import Table from "@/components/Table/Table1.vue";
import { fetchChamkaList, edit, update, destroy as deleteTrade, } from "@/api/trade";
// import { _ } from "vue-underscore";
import getRoleSlug from "@/store/app.js";
import { store } from "@/api/market-watch";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";



export default {
  name: "ListTrades",
  components: {
    Table,
  },
  data() {
    return {
      dialogVisible: false,
      tableActionViewVisibility:false,
      tableActionDeleteVisibility : false,
      dialogType: "create",
      loading: false,
      search: "",
      listDataTemp: null,
      tablePagination: {},
      formData: {},
      formErrors: [],
      formLabelWidth: "120px",
      isExcelReady: false,
      config: [
        {
          label: "Script",
          prop: "script",
          className: "blueFont",
        },
        {
          label: "Segment",
          prop: "segment",
          className: "blueFont",
        },
        {
          label: "Lots / Units",
          prop: "lot_and_unit",
          className: "blueFont",
        },
      ],
      listData: {
        data: {
          item: [],
        },
      },
      listAdmins: [],
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        date_from:"",
        date_to:""
      },
      data: {},
      // data: {
      //   data: {
      //     item: [
      //       {
      //         id: "001",
      //         script: "CRUDEOIL_17DEC2021",
      //         segment: "MCX",
      //         user_id: "vijay1234 : vijay",
      //         buy_rate: "5667",
      //         sell_rate: "5680",
      //         lots_units: "1 lots",
      //         bought_at: "2021-11-22 20:22:15",
      //         sold_at: "2021-11-22 20:37:54",
      //       },
      //     ],
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    this.getList();
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList();
      this.tableActionDeleteVisibility = true;
      this.tableActionViewVisibility = true;
    }else{
      //this.tableActionDeleteVisibility = true;
      this.tableActionViewVisibility = true;
    }
  },
  methods: {
   
    handleReset() {
      this.listQuery = {}
      this.listQuery.page = 1;
      this.getList();
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
     // //////////////////
    // HANDLE SEARCH
    // //////////////////
    // handleSearch() {
    //   this.listData.data.item = this.listDataTemp;
    //   let s = this.search;
    //   this.listData.data.item = _.filter(this.listData.data.item, function (d) {
    //     return d["script"].includes(s);
    //   });
    // },
    handleSelectSearchType() {
      this.handleSearch()
    },
    handleSearch() {
      if(this.listQuery.date_range){
        this.listQuery.date_from = this.listQuery.date_range[0]
        this.listQuery.date_to = this.listQuery.date_range[1]
      }
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    getList(showLoading) {
      let t= this;
     if(showLoading !== false){      
        this.loading = true;
      }
      fetchChamkaList(this.listQuery).then((response) => {
        // this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;

        if(showLoading !== false){      
          this.loading = false;
        }
        if(getRoleSlug.state.roleSlug != 'superadmin') {
          setTimeout(function () {
             t.getList(false)
          }, 30000);
        }
        
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleViewDetails(data) {
       console.log(data)
       if(getRoleSlug.state.roleSlug == 'superadmin') {
        return this.$router.push("/super-admin/trades/trades-view/" +data.id );
      } else {
        return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trades/trades-view/" + data.id);
      }
    },
    // //////////////////
    // CREATE
    // //////////////////
    handleCreate() {
      this.formData = {
        segment: "",
      };
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
        store(data)
          .then((response) => {
            if (response.data.success === true) {
              this.dialogVisible = false;
              this.flashSuccess(response.data.message);
              this.getList();
              this.loading = false;
            } else {
              this.flashError(response.data.message);
              this.formErrors = this.validationErrors(response.data.message);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // //////////////////
    // EDIT
    // //////////////////
    handleEdit(id) {
      this.fetchData(id);
    },
    fetchData(id) {
      this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.loading = false;
            this.dialogType = "edit";
            this.dialogVisible = true;
          } else {
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    handleUpdate(data) {
      this.loading = true;
      update(data.id, data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.loading = false;
            this.flashSuccess(response.data.message);
            //this.getList();

            if(getRoleSlug.state.roleSlug == 'superadmin') {
              return this.$router.push("/super-admin/trades/trades-view/" +data.id );
            } else {
              return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trades/trades-view/" + data.id);
            }
          } else {
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      console.log(data.id);
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        deleteTrade(data.id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },
  },
};
</script>
